type AppInstallPrompt = () => Promise<void>;

class InstallationService {
    private appInstallPrompt?: AppInstallPrompt;
    private onInstallAvailablePromise: Promise<void>;
    private onInstallAvailableSuccessor!: () => any;
    private onAppInstalledPromise: Promise<void>;
    private onAppInstalledSuccessor!: () => any;

    constructor() {
        this.onInstallAvailablePromise = new Promise(resolve => {
            this.onInstallAvailableSuccessor = resolve;
        });

        this.onAppInstalledPromise = new Promise(resolve => {
            this.onAppInstalledSuccessor = resolve;
        });

        const beforeInstallPromptListener = this.onBeforeInstallPrompt.bind(this);
        const appInstalledListener = this.onAppInstalled.bind(this);

        window.addEventListener('beforeinstallprompt', beforeInstallPromptListener);
        window.addEventListener('appinstalled', appInstalledListener);
    }

    private onBeforeInstallPrompt(event: any) {
        event.preventDefault();
        this.appInstallPrompt = event.prompt.bind(event);
        this.onInstallAvailableSuccessor();
    }

    private onAppInstalled() {
        this.appInstallPrompt = undefined;
        this.onAppInstalledSuccessor();
    }

    get installReady(): Promise<void> {
        return this.onInstallAvailablePromise;
    }

    get appInstalled(): Promise<void> {
        return this.onAppInstalledPromise;
    }

    async prompt(): Promise<void> {
        if (this.appInstallPrompt) {
            await this.appInstallPrompt();
        }
    }

}

const installationService = new InstallationService();

export {installationService as InstallationService};
