/* tslint:disable */
/* eslint-disable */
/**
 * bac-pwa
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BrandDTO,
    BrandDTOFromJSON,
    BrandDTOFromJSONTyped,
    BrandDTOToJSON,
    CategoryDTO,
    CategoryDTOFromJSON,
    CategoryDTOFromJSONTyped,
    CategoryDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateUserProductDTO
 */
export interface UpdateUserProductDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProductDTO
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserProductDTO
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProductDTO
     */
    imageName?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserProductDTO
     */
    categoryId?: number;
    /**
     * 
     * @type {CategoryDTO}
     * @memberof UpdateUserProductDTO
     */
    category?: CategoryDTO;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserProductDTO
     */
    brandId?: number;
    /**
     * 
     * @type {BrandDTO}
     * @memberof UpdateUserProductDTO
     */
    brand?: BrandDTO;
}

export function UpdateUserProductDTOFromJSON(json: any): UpdateUserProductDTO {
    return UpdateUserProductDTOFromJSONTyped(json, false);
}

export function UpdateUserProductDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserProductDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'imageName': !exists(json, 'imageName') ? undefined : json['imageName'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'category': !exists(json, 'category') ? undefined : CategoryDTOFromJSON(json['category']),
        'brandId': !exists(json, 'brandId') ? undefined : json['brandId'],
        'brand': !exists(json, 'brand') ? undefined : BrandDTOFromJSON(json['brand']),
    };
}

export function UpdateUserProductDTOToJSON(value?: UpdateUserProductDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'price': value.price,
        'imageName': value.imageName,
        'categoryId': value.categoryId,
        'category': CategoryDTOToJSON(value.category),
        'brandId': value.brandId,
        'brand': BrandDTOToJSON(value.brand),
    };
}


