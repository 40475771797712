/* tslint:disable */
/* eslint-disable */
/**
 * bac-pwa
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BrandDTO,
    BrandDTOFromJSON,
    BrandDTOFromJSONTyped,
    BrandDTOToJSON,
    CategoryDTO,
    CategoryDTOFromJSON,
    CategoryDTOFromJSONTyped,
    CategoryDTOToJSON,
    UserDTO,
    UserDTOFromJSON,
    UserDTOFromJSONTyped,
    UserDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserProductDTO
 */
export interface UserProductDTO {
    /**
     * 
     * @type {number}
     * @memberof UserProductDTO
     */
    productId: number;
    /**
     * 
     * @type {string}
     * @memberof UserProductDTO
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof UserProductDTO
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof UserProductDTO
     */
    imageName?: string;
    /**
     * 
     * @type {number}
     * @memberof UserProductDTO
     */
    categoryId?: number;
    /**
     * 
     * @type {CategoryDTO}
     * @memberof UserProductDTO
     */
    category?: CategoryDTO;
    /**
     * 
     * @type {number}
     * @memberof UserProductDTO
     */
    brandId?: number;
    /**
     * 
     * @type {BrandDTO}
     * @memberof UserProductDTO
     */
    brand?: BrandDTO;
    /**
     * 
     * @type {UserDTO}
     * @memberof UserProductDTO
     */
    user?: UserDTO;
    /**
     * 
     * @type {number}
     * @memberof UserProductDTO
     */
    userId: number;
    /**
     * 
     * @type {Date}
     * @memberof UserProductDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserProductDTO
     */
    updatedAt: Date;
}

export function UserProductDTOFromJSON(json: any): UserProductDTO {
    return UserProductDTOFromJSONTyped(json, false);
}

export function UserProductDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProductDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['productId'],
        'name': json['name'],
        'price': json['price'],
        'imageName': !exists(json, 'imageName') ? undefined : json['imageName'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'category': !exists(json, 'category') ? undefined : CategoryDTOFromJSON(json['category']),
        'brandId': !exists(json, 'brandId') ? undefined : json['brandId'],
        'brand': !exists(json, 'brand') ? undefined : BrandDTOFromJSON(json['brand']),
        'user': !exists(json, 'user') ? undefined : UserDTOFromJSON(json['user']),
        'userId': json['userId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function UserProductDTOToJSON(value?: UserProductDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productId': value.productId,
        'name': value.name,
        'price': value.price,
        'imageName': value.imageName,
        'categoryId': value.categoryId,
        'category': CategoryDTOToJSON(value.category),
        'brandId': value.brandId,
        'brand': BrandDTOToJSON(value.brand),
        'user': UserDTOToJSON(value.user),
        'userId': value.userId,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}


