/* tslint:disable */
/* eslint-disable */
/**
 * bac-pwa
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CredentialsDTO
 */
export interface CredentialsDTO {
    /**
     * 
     * @type {string}
     * @memberof CredentialsDTO
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialsDTO
     */
    password: string;
}

export function CredentialsDTOFromJSON(json: any): CredentialsDTO {
    return CredentialsDTOFromJSONTyped(json, false);
}

export function CredentialsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CredentialsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'password': json['password'],
    };
}

export function CredentialsDTOToJSON(value?: CredentialsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'password': value.password,
    };
}


