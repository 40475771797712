export enum Routes {
	LOGIN = 'login',
	REGISTER = 'register',

    NOT_FOUND = 'notFound',

    PRODUCTS = 'products',
    SCANNER = 'scanner',
    SINGLE_PRODUCT = 'single_product'
}
