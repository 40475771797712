import {RouteConfig} from 'vue-router';
import {Routes} from './Routes';
import RouteGuard from './RouteGuard';

const appRoutes: RouteConfig[] = [
    {
        path: '/',
        component: () => import('@/views/Shell.vue'),
        children: [
            {
                path: '',
                name: Routes.PRODUCTS,
                component: () => import('@/views/app/Products.vue')
            },
            {
                path: 'scanner',
                name: Routes.SCANNER,
                component: () => import('@/views/app/Scanner.vue')
            },
            {
                path: 'product/:id',
                name: Routes.SINGLE_PRODUCT,
                component: () => import('@/views/app/SingleProduct.vue')
            }
        ],
        beforeEnter: RouteGuard.requiresAuthentication
    }
];

export default appRoutes;
