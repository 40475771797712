import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {ApiService} from '@/services/ApiService';
import {BrandDTO, CategoryDTO, NewUserProductDTO, UpdateUserProductDTO, UserProductDTO} from '@/generated';
import {Vue} from 'vue-property-decorator';

export interface ApiSyncOptions {
    offset?: number,
    since?: Date
}

export interface ProductModuleState {
    products: UserProductDTO[],
    productsSyncInfo: ApiSyncOptions,
    brands: BrandDTO[],
    brandsSyncInfo: ApiSyncOptions,
    categories: CategoryDTO[],
    categoriesSyncInfo: ApiSyncOptions
}

export enum ProductModuleMutations {
    setProducts = 'setProducts',
    productsSynced = 'productsSynced',
    productsUpdated = 'productsUpdated',
    setBrands = 'setBrands',
    brandsSynced = 'brandsSynced',
    brandsUpdated = 'brandsUpdated',
    setCategories = 'setCategories',
    categoriesSynced = 'categoriesSynced',
    categoriesUpdated = 'categoriesUpdated'
}

@Module({
    name: 'product'
})
export default class ProductModule extends VuexModule implements ProductModuleState {
    products: UserProductDTO[] = [];
    brands: BrandDTO[] = [];
    categories: CategoryDTO[] = [];

    productsSyncInfo: ApiSyncOptions = {};
    brandsSyncInfo: ApiSyncOptions = {};
    categoriesSyncInfo: ApiSyncOptions = {};

    @Mutation
    [ProductModuleMutations.setProducts](products: UserProductDTO[]) {
        products.forEach(product => {
            const existingProductIndex = this.products.findIndex(existingProduct => product.productId === existingProduct.productId);
            if (existingProductIndex >= 0) {
                Vue.set(this.products, existingProductIndex, product);
            } else {
                this.products.push(product);
            }
        });
    }

    @Mutation
    [ProductModuleMutations.productsUpdated](offset: number) {
        Vue.set(this.productsSyncInfo, 'offset', offset);
    }

    @Mutation
    [ProductModuleMutations.productsSynced](since: Date) {
        this.productsSyncInfo = {since};
    }

    @Mutation
    [ProductModuleMutations.setCategories](categories: CategoryDTO[]) {
        categories.forEach(category => {
            const existingCategoryIndex = this.categories.findIndex(existingCategory => category.categoryId === existingCategory.categoryId);
            if (existingCategoryIndex >= 0) {
                Vue.set(this.categories, existingCategoryIndex, category);
            } else {
                this.categories.push(category);
            }
        });
    }

    @Mutation
    [ProductModuleMutations.categoriesUpdated](offset: number) {
        Vue.set(this.categoriesSyncInfo, 'offset', offset);
    }

    @Mutation
    [ProductModuleMutations.categoriesSynced](since: Date) {
        this.categoriesSyncInfo = {since};
    }

    @Mutation
    [ProductModuleMutations.setBrands](brands: BrandDTO[]) {
        brands.forEach(brand => {
            const existingBrandIndex = this.brands.findIndex(existingBrand => brand.brandId === existingBrand.brandId);
            if (existingBrandIndex >= 0) {
                Vue.set(this.brands, existingBrandIndex, brand);
            } else {
                this.brands.push(brand);
            }
        });
    }

    @Mutation
    [ProductModuleMutations.brandsUpdated](offset: number) {
        Vue.set(this.brandsSyncInfo, 'offset', offset);
    }

    @Mutation
    [ProductModuleMutations.brandsSynced](since: Date) {
        this.brandsSyncInfo = {since};
    }

    @Action({rawError: true})
    async refreshProducts() {
        const productResponse = await ApiService.getProducts({
            since: this.productsSyncInfo.since || undefined,
            offset: this.productsSyncInfo.offset || undefined
        });
        this.context.commit(ProductModuleMutations.setProducts, productResponse.data);

        if (productResponse.stream || productResponse.limit !== productResponse.totalCount) {
            this.context.commit(ProductModuleMutations.brandsUpdated, productResponse.offset + productResponse.limit);
        } else {
            this.context.commit(ProductModuleMutations.productsSynced, productResponse.since);
        }
    }

    @Action({rawError: true})
    async refreshCategories() {
        const categoryResponse = await ApiService.getCategories({
            since: this.categoriesSyncInfo.since || undefined,
            offset: this.categoriesSyncInfo.offset || undefined
        });
        this.context.commit(ProductModuleMutations.setCategories, categoryResponse.data);

        if (categoryResponse.stream || categoryResponse.limit !== categoryResponse.totalCount) {
            this.context.commit(ProductModuleMutations.categoriesUpdated, categoryResponse.offset + categoryResponse.limit);
        } else {
            this.context.commit(ProductModuleMutations.categoriesSynced, categoryResponse.since);
        }
    }

    @Action({rawError: true})
    async refreshBrands() {
        const brandResponse = await ApiService.getBrands({
            since: this.brandsSyncInfo.since || undefined,
            offset: this.brandsSyncInfo.offset || undefined
        });
        this.context.commit(ProductModuleMutations.setBrands, brandResponse.data);

        if (brandResponse.stream || brandResponse.limit !== brandResponse.totalCount) {
            this.context.commit(ProductModuleMutations.brandsUpdated, brandResponse.offset + brandResponse.limit);
        } else {
            this.context.commit(ProductModuleMutations.brandsSynced, brandResponse.since);
        }
    }

    @Action({rawError: true})
    async createProduct(product: NewUserProductDTO) {
        const createdProduct = await ApiService.createProduct({
            newUserProductDTO: product
        });
        this.context.commit(ProductModuleMutations.setProducts, [createdProduct]);
    }

    @Action({rawError: true})
    async updateProduct(productId: number, product: UpdateUserProductDTO) {
        const updatedProduct = await ApiService.updateProduct({
            productId,
            updateUserProductDTO: product
        });
        this.context.commit(ProductModuleMutations.setProducts, [updatedProduct]);
    }

    @Action({rawError: true})
    async deleteProduct() {

    }
}
