import {MutationPayload, Plugin, Store} from 'vuex';
import {RootMutations, RootState} from '@/store';
import {BrandDTO, CategoryDTO, UserProductDTO} from '@/generated';
import Dexie, {Table} from 'dexie';
import {merge} from 'lodash';
import {UserModuleMutations} from '@/store/modules/UserModule';
import {ApiSyncOptions, ProductModuleMutations} from '@/store/modules/ProductModule';

type DeepPartial<T> = T extends Function ? T : (T extends object ? { [P in keyof T]?: DeepPartial<T[P]>; } : T);

enum GeneralStorageKeys {
    USER = 'user',
    PRODUCT_SYNC = 'productSync',
    BRAND_SYNC = 'brandSync',
    CATEGORY_SYNC = 'categorySync'
}

interface GeneralStorageItem {
    key: GeneralStorageKeys,
    item?: any
}

class PersistencePlugin extends Dexie {
    private static readonly DB_NAME = 'application';

    private static readonly STORAGE_NAMES = {
        GENERAL: 'general',
        PRODUCTS: 'products',
        CATEGORIES: 'categories',
        BRANDS: 'brands'
    };

    private readonly generalStorage: Table<GeneralStorageItem, GeneralStorageKeys>;
    private readonly productStorage: Table<UserProductDTO, number>;
    private readonly brandStorage: Table<BrandDTO, number>;
    private readonly categoryStorage: Table<CategoryDTO, number>;

    private restorePromise: Promise<void>;
    private restoreSuccessCallback!: Function;
    private restoreFailureCallback!: Function;

    constructor() {
        super(PersistencePlugin.DB_NAME);

        this.version(1).stores({
            [PersistencePlugin.STORAGE_NAMES.GENERAL]: '&key',
            [PersistencePlugin.STORAGE_NAMES.PRODUCTS]: '&productId',
            [PersistencePlugin.STORAGE_NAMES.BRANDS]: '&brandId',
            [PersistencePlugin.STORAGE_NAMES.CATEGORIES]: '&categoryId'
        });

        this.generalStorage = this.table(PersistencePlugin.STORAGE_NAMES.GENERAL);
        this.productStorage = this.table(PersistencePlugin.STORAGE_NAMES.PRODUCTS);
        this.brandStorage = this.table(PersistencePlugin.STORAGE_NAMES.BRANDS);
        this.categoryStorage = this.table(PersistencePlugin.STORAGE_NAMES.CATEGORIES);

        this.restorePromise = new Promise(((resolve, reject) => {
            this.restoreSuccessCallback = resolve;
            this.restoreFailureCallback = reject;
        }));
    }

    get plugin(): Plugin<RootState> {
        return this.onStoreInit.bind(this);
    }

    get restored(): Promise<void> {
        return this.restorePromise;
    }

    onStoreInit(store: Store<RootState>) {
        try {
            this.restoreState(store);
            this.restoreSuccessCallback();
        } catch (err) {
            this.restoreFailureCallback(err);
        }

        store.subscribe(this.saveState.bind(this));
    }

    async saveState({type, payload}: MutationPayload, state: RootState): Promise<any> {
        switch (type) {
            case RootMutations.resetUserData:
                return Promise.all([
                    this.generalStorage.clear(),
                    this.productStorage.clear(),
                    this.brandStorage.clear(),
                    this.categoryStorage.clear()
                ]);
            case UserModuleMutations.setUser:
                return this.generalStorage.put({
                    key: GeneralStorageKeys.USER,
                    item: payload
                });
            case ProductModuleMutations.productsSynced:
            case ProductModuleMutations.productsUpdated:
                return this.generalStorage.put({
                    key: GeneralStorageKeys.PRODUCT_SYNC,
                    item: state.product.productsSyncInfo
                });
            case ProductModuleMutations.brandsSynced:
            case ProductModuleMutations.brandsUpdated:
                return this.generalStorage.put({
                    key: GeneralStorageKeys.BRAND_SYNC,
                    item: state.product.brandsSyncInfo
                });
            case ProductModuleMutations.categoriesSynced:
            case ProductModuleMutations.categoriesUpdated:
                return this.generalStorage.put({
                    key: GeneralStorageKeys.CATEGORY_SYNC,
                    item: state.product.categoriesSyncInfo
                });
            case ProductModuleMutations.setProducts:
                return this.productStorage.bulkPut(payload);
            case ProductModuleMutations.setCategories:
                return this.categoryStorage.bulkPut(payload);
            case ProductModuleMutations.setBrands:
                return this.brandStorage.bulkPut(payload);
        }
    }

    async restoreState(store: Store<RootState>) {
        const [[userDTO, productsSyncInfo, brandsSyncInfo, categoriesSyncInfo], products, brands, categories] = await Promise.all([
            this.generalStorage.bulkGet([
                GeneralStorageKeys.USER,
                GeneralStorageKeys.PRODUCT_SYNC,
                GeneralStorageKeys.BRAND_SYNC,
                GeneralStorageKeys.CATEGORY_SYNC
            ]),
            this.productStorage.toArray(),
            this.brandStorage.toArray(),
            this.categoryStorage.toArray()
        ]);

        const state: DeepPartial<RootState> = {
            product: {
                productsSyncInfo: PersistencePlugin.parseGeneralStorageItem(productsSyncInfo),
                brandsSyncInfo: PersistencePlugin.parseGeneralStorageItem(brandsSyncInfo),
                categoriesSyncInfo: PersistencePlugin.parseGeneralStorageItem(categoriesSyncInfo),
                products, categories, brands
            },
            user: {
                user: userDTO?.item
            }
        };

        store.replaceState(merge(state, store.state));
    }

    private static parseGeneralStorageItem(value?: GeneralStorageItem): ApiSyncOptions | undefined {
        if (value?.item) {
            value.item.since = new Date(value.item.since);
        }
        return value?.item;
    }
}

export const persistencePlugin = new PersistencePlugin();
