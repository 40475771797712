import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {AuthorizationDTO, CredentialsDTO, UserDTO} from '@api/api';
import {ApiService} from '@/services/ApiService';

export interface UserModuleState {
    token?: string,
    user?: UserDTO
}

export enum UserModuleMutations {
    setUser = 'setUser'
}

@Module({
    name: 'user'
})
export default class UserModule extends VuexModule implements UserModuleState {
    user: UserDTO = {} as UserDTO;
    token: string = ApiService.apiKey || '';

    get isLoggedIn(): boolean {
        return this.token !== '';
    }

    @Mutation
    [UserModuleMutations.setUser](authorizationDTO: AuthorizationDTO) {
        ApiService.apiKey = authorizationDTO.token;
        this.user = authorizationDTO.user;
        this.token = authorizationDTO.token;
    }

    @Action({rawError: true})
    async register(credentialsDTO: CredentialsDTO) {
        const authorizationDTO = await ApiService.register({credentialsDTO});
        this.context.commit(UserModuleMutations.setUser, authorizationDTO);
    }

    @Action({rawError: true})
    async login(credentialsDTO: CredentialsDTO) {
        const authorizationDTO = await ApiService.login({credentialsDTO});
        this.context.commit(UserModuleMutations.setUser, authorizationDTO);
    }

    @Action({rawError: true})
    async logout() {
        try {
            await ApiService.logout();
        } catch (err) {
            console.error('Cannot send server logout message');
        }
        this.context.commit('resetUserData');
    }

    @Action({rawError: true})
    async validateLogin() {
        try {
            const authorizationDTO = await ApiService.verifyLogin();
            this.context.commit(UserModuleMutations.setUser, authorizationDTO);
        } catch (e) {
            if (e instanceof Response && e.status === 401) {
                this.context.commit('resetUserData');
                throw e;
            }
        }
    }
}
