/* tslint:disable */
/* eslint-disable */
/**
 * bac-pwa
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrandDTO
 */
export interface BrandDTO {
    /**
     * 
     * @type {number}
     * @memberof BrandDTO
     */
    brandId: number;
    /**
     * 
     * @type {string}
     * @memberof BrandDTO
     */
    name: string;
}

export function BrandDTOFromJSON(json: any): BrandDTO {
    return BrandDTOFromJSONTyped(json, false);
}

export function BrandDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrandDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'brandId': json['brandId'],
        'name': json['name'],
    };
}

export function BrandDTOToJSON(value?: BrandDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'brandId': value.brandId,
        'name': value.name,
    };
}


