import {DefaultApi} from '@/generated';
import {Configuration, ConfigurationParameters} from '@api/index';

class ApiService extends DefaultApi {
    public static readonly API_STORAGE_KEY = 'jwt';

    constructor() {
        const configParams: ConfigurationParameters = {
            apiKey: () => this.apiKey || '',
            basePath: location.origin + '/api'
        };

        super(new Configuration(configParams));
    }

    public get apiKey(): string | null {
        return localStorage.getItem(ApiService.API_STORAGE_KEY);
    }

    public set apiKey(token: string | null) {
        if (token) {
            localStorage.setItem(ApiService.API_STORAGE_KEY, token);
        } else {
            localStorage.removeItem(ApiService.API_STORAGE_KEY);
        }
    }
}

const apiService = new ApiService();

export {apiService as ApiService};
