import {NavigationGuard} from 'vue-router';
import {Routes} from '@/router/Routes';
import {getModule} from 'vuex-module-decorators';
import UserModule from '@/store/modules/UserModule';
import store from '@/store';

export default class RouteGuard {
    private static readonly USER_MODULE = getModule(UserModule, store);

    public static requiresAuthentication: NavigationGuard = (_to, _from, next) => {
        if (RouteGuard.USER_MODULE.isLoggedIn) {
            return next();
        }
        return next({name: Routes.LOGIN});
    }

    public static requiresNoAuthentication: NavigationGuard = (_to, _from, next) => {
        if (!RouteGuard.USER_MODULE.isLoggedIn) {
            return next();
        }
        return next({name: Routes.PRODUCTS});
    }
}
