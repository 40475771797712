/* tslint:disable */
/* eslint-disable */
/**
 * bac-pwa
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BrandDTO,
    BrandDTOFromJSON,
    BrandDTOFromJSONTyped,
    BrandDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaginatedResponseBrandDTO
 */
export interface PaginatedResponseBrandDTO {
    /**
     * 
     * @type {number}
     * @memberof PaginatedResponseBrandDTO
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedResponseBrandDTO
     */
    limit: number;
    /**
     * 
     * @type {Date}
     * @memberof PaginatedResponseBrandDTO
     */
    since: Date;
    /**
     * 
     * @type {Array<BrandDTO>}
     * @memberof PaginatedResponseBrandDTO
     */
    data: Array<BrandDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedResponseBrandDTO
     */
    stream?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginatedResponseBrandDTO
     */
    totalCount: number;
}

export function PaginatedResponseBrandDTOFromJSON(json: any): PaginatedResponseBrandDTO {
    return PaginatedResponseBrandDTOFromJSONTyped(json, false);
}

export function PaginatedResponseBrandDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedResponseBrandDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offset': json['offset'],
        'limit': json['limit'],
        'since': (new Date(json['since'])),
        'data': ((json['data'] as Array<any>).map(BrandDTOFromJSON)),
        'stream': !exists(json, 'stream') ? undefined : json['stream'],
        'totalCount': json['totalCount'],
    };
}

export function PaginatedResponseBrandDTOToJSON(value?: PaginatedResponseBrandDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offset': value.offset,
        'limit': value.limit,
        'since': (value.since.toISOString()),
        'data': ((value.data as Array<any>).map(BrandDTOToJSON)),
        'stream': value.stream,
        'totalCount': value.totalCount,
    };
}


