import { render, staticRenderFns } from "./App.vue?vue&type=template&id=9b1dca9c&"
var script = {}
import style0 from "bootstrap/scss/bootstrap.scss?vue&type=style&index=0&lang=scss&"
import style1 from "vue-virtual-scroller/dist/vue-virtual-scroller.css?vue&type=style&index=1&lang=css&"
import style2 from "@mdi/font/css/materialdesignicons.min.css?vue&type=style&index=2&lang=css&"
import style3 from "./App.vue?vue&type=style&index=3&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports