import io from 'socket.io-client';
import ConnectOpts = SocketIOClient.ConnectOpts;

type EventListener = (event: string, fn: Function) => any;

class SocketService {
    //private static readonly MESSAGE_TIMEOUT = 2000;

    private socket: SocketIOClient.Socket;

    constructor(options?: ConnectOpts) {
        const transports = ['websocket'];
        if (!window.WebSocket) {
            transports.push('polling');
        }

        this.socket = io({
            hostname: '//' + window.location.hostname,
            port: window.location.port,
            path: '/api/ws',
            autoConnect: false,
            transports: ['websocket'],
            ...options
        });

        this.socket.on('reconnect_attempt', () => {
            if (!this.socket.io.opts.transports?.includes('polling')) {
                this.socket.io.opts.transports?.push('polling');
            }
        });
    }

    get isConnected(): boolean {
        return this.socket.connected;
    }

    connect(token: string): Promise<void> {
        if (this.socket.connected) {
            return Promise.resolve();
        }
        return new Promise<void>(((resolve, reject) => {
            const socket = this.socket;

            function unsetConnectionListeners() {
                socket.off('connect_error', connectionFailure);
                socket.off('connect_timeout', connectionFailure);
                socket.off('connect', connectionSuccess);
            }

            function connectionFailure(err: any) {
                unsetConnectionListeners();
                return reject(err);
            }

            function connectionSuccess() {
                unsetConnectionListeners();
                return resolve();
            }

            this.socket.once('connect_error', connectionFailure);
            this.socket.once('connect_timeout', connectionFailure);
            this.socket.once('connect', connectionSuccess);
            this.socket.io.opts.query = {
                jwt: token
            };

            //@ts-ignore typings are fucked up dagain
            this.socket.connect();
        }));
    }

    disconnect() {
        if (!this.socket.disconnected) {
            this.socket.disconnect();
        }
    }

    get on(): EventListener {
        return this.socket.on.bind(this.socket);
    }

    get addEventListener(): EventListener {
        return this.socket.addEventListener.bind(this.socket);
    }

    get once(): EventListener {
        return this.socket.once.bind(this.socket);
    }

    get off(): EventListener {
        return this.socket.off.bind(this.socket);
    }

    get removeEventListener(): EventListener {
        return this.socket.removeEventListener.bind(this.socket);
    }
}

const socketService = new SocketService();

export {socketService as SocketService};
