import {Workbox} from 'workbox-window';

if (process.env.NODE_ENV === 'production') {
    if ('serviceWorker' in navigator) {
        const wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);
        wb.addEventListener('controlling', () => {
            window.location.reload();
        });
        wb.register();
    }
}
