import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import authRoutes from './authRoutes';
import errorRoutes from './errorRoutes';
import appRoutes from '@/router/appRoutes';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    ...authRoutes,
    ...errorRoutes,
    ...appRoutes
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
