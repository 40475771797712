import {RouteConfig} from 'vue-router';
import {Routes} from './Routes';
import Auth from '../views/auth/Auth.vue';
import Login from '../views/auth/Login.vue';
import Register from '../views/auth/Register.vue';
import RouteGuard from './RouteGuard';

const authRoutes: RouteConfig[] = [
    {
        path: '/login',
        component: Auth,
        children: [
            {
                path: '',
                name: Routes.LOGIN,
                component: Login
            },
            {
                path: '/register',
                name: Routes.REGISTER,
                component: Register
            }
        ],
        beforeEnter: RouteGuard.requiresNoAuthentication
    }
];

export default authRoutes;
