import Vue from 'vue';
import Vuex from 'vuex';
import {DefaultApi, UserDTO} from '@/generated';
import {config as vuexConfig} from 'vuex-module-decorators';
import websocketPlugin from '@/store/plugins/WebsocketPlugin';
import {persistencePlugin} from '@/store/plugins/PersistencePlugin';
import UserModule, {UserModuleState} from '@/store/modules/UserModule';
import ProductModule, {ProductModuleState} from '@/store/modules/ProductModule';
import {ApiService} from '@/services/ApiService';

Vue.use(Vuex);
vuexConfig.rawError = true;

export interface RootState {
    api: DefaultApi,
    user: UserModuleState,
    product: ProductModuleState
}

export enum RootMutations {
    resetUserData = 'resetUserData'
}

const store = new Vuex.Store<RootState>({
    strict: process.env.NODE_ENV !== 'production',
    state: {
        api: new DefaultApi()
    } as RootState,
    modules: {
        product: ProductModule,
        user: UserModule
    },
    mutations: {
        [RootMutations.resetUserData](state: RootState) {
            ApiService.apiKey = null;

            state.user.user = {} as UserDTO;
            state.user.token = '';
            state.product.productsSyncInfo = {};
            state.product.brandsSyncInfo = {};
            state.product.categoriesSyncInfo = {};
            state.product.products = [];
            state.product.brands = [];
            state.product.categories = [];
        }
    },
    plugins: [websocketPlugin, persistencePlugin.plugin]
});

export default store;
