/* tslint:disable */
/* eslint-disable */
/**
 * bac-pwa
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationDTO,
    AuthorizationDTOFromJSON,
    AuthorizationDTOToJSON,
    CredentialsDTO,
    CredentialsDTOFromJSON,
    CredentialsDTOToJSON,
    HttpError,
    HttpErrorFromJSON,
    HttpErrorToJSON,
    NewUserProductDTO,
    NewUserProductDTOFromJSON,
    NewUserProductDTOToJSON,
    PaginatedResponseBrandDTO,
    PaginatedResponseBrandDTOFromJSON,
    PaginatedResponseBrandDTOToJSON,
    PaginatedResponseCategoryDTO,
    PaginatedResponseCategoryDTOFromJSON,
    PaginatedResponseCategoryDTOToJSON,
    PaginatedResponseUserProductDTO,
    PaginatedResponseUserProductDTOFromJSON,
    PaginatedResponseUserProductDTOToJSON,
    UpdateUserProductDTO,
    UpdateUserProductDTOFromJSON,
    UpdateUserProductDTOToJSON,
    UserProductDTO,
    UserProductDTOFromJSON,
    UserProductDTOToJSON,
} from '../models';

export interface CreateProductRequest {
    newUserProductDTO: NewUserProductDTO;
}

export interface DeleteProductRequest {
    productId: number;
}

export interface GetBrandsRequest {
    offset?: number;
    limit?: number;
    since?: Date;
}

export interface GetCategoriesRequest {
    offset?: number;
    limit?: number;
    since?: Date;
}

export interface GetProductsRequest {
    offset?: number;
    limit?: number;
    since?: Date;
}

export interface LoginRequest {
    credentialsDTO: CredentialsDTO;
}

export interface RegisterRequest {
    credentialsDTO: CredentialsDTO;
}

export interface UpdateProductRequest {
    productId: number;
    updateUserProductDTO: UpdateUserProductDTO;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async createProductRaw(requestParameters: CreateProductRequest): Promise<runtime.ApiResponse<UserProductDTO>> {
        if (requestParameters.newUserProductDTO === null || requestParameters.newUserProductDTO === undefined) {
            throw new runtime.RequiredError('newUserProductDTO','Required parameter requestParameters.newUserProductDTO was null or undefined when calling createProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // jwt authentication
        }

        const response = await this.request({
            path: `/products/product`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewUserProductDTOToJSON(requestParameters.newUserProductDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProductDTOFromJSON(jsonValue));
    }

    /**
     */
    async createProduct(requestParameters: CreateProductRequest): Promise<UserProductDTO> {
        const response = await this.createProductRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteProductRaw(requestParameters: DeleteProductRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling deleteProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // jwt authentication
        }

        const response = await this.request({
            path: `/products/product/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProduct(requestParameters: DeleteProductRequest): Promise<void> {
        await this.deleteProductRaw(requestParameters);
    }

    /**
     */
    async getBrandsRaw(requestParameters: GetBrandsRequest): Promise<runtime.ApiResponse<PaginatedResponseBrandDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.since !== undefined) {
            queryParameters['since'] = (requestParameters.since as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // jwt authentication
        }

        const response = await this.request({
            path: `/products/brands`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedResponseBrandDTOFromJSON(jsonValue));
    }

    /**
     */
    async getBrands(requestParameters: GetBrandsRequest): Promise<PaginatedResponseBrandDTO> {
        const response = await this.getBrandsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCategoriesRaw(requestParameters: GetCategoriesRequest): Promise<runtime.ApiResponse<PaginatedResponseCategoryDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.since !== undefined) {
            queryParameters['since'] = (requestParameters.since as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // jwt authentication
        }

        const response = await this.request({
            path: `/products/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedResponseCategoryDTOFromJSON(jsonValue));
    }

    /**
     */
    async getCategories(requestParameters: GetCategoriesRequest): Promise<PaginatedResponseCategoryDTO> {
        const response = await this.getCategoriesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getProductsRaw(requestParameters: GetProductsRequest): Promise<runtime.ApiResponse<PaginatedResponseUserProductDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.since !== undefined) {
            queryParameters['since'] = (requestParameters.since as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // jwt authentication
        }

        const response = await this.request({
            path: `/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedResponseUserProductDTOFromJSON(jsonValue));
    }

    /**
     */
    async getProducts(requestParameters: GetProductsRequest): Promise<PaginatedResponseUserProductDTO> {
        const response = await this.getProductsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async loginRaw(requestParameters: LoginRequest): Promise<runtime.ApiResponse<AuthorizationDTO>> {
        if (requestParameters.credentialsDTO === null || requestParameters.credentialsDTO === undefined) {
            throw new runtime.RequiredError('credentialsDTO','Required parameter requestParameters.credentialsDTO was null or undefined when calling login.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CredentialsDTOToJSON(requestParameters.credentialsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizationDTOFromJSON(jsonValue));
    }

    /**
     */
    async login(requestParameters: LoginRequest): Promise<AuthorizationDTO> {
        const response = await this.loginRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async logoutRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // jwt authentication
        }

        const response = await this.request({
            path: `/auth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async logout(): Promise<void> {
        await this.logoutRaw();
    }

    /**
     */
    async registerRaw(requestParameters: RegisterRequest): Promise<runtime.ApiResponse<AuthorizationDTO>> {
        if (requestParameters.credentialsDTO === null || requestParameters.credentialsDTO === undefined) {
            throw new runtime.RequiredError('credentialsDTO','Required parameter requestParameters.credentialsDTO was null or undefined when calling register.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CredentialsDTOToJSON(requestParameters.credentialsDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizationDTOFromJSON(jsonValue));
    }

    /**
     */
    async register(requestParameters: RegisterRequest): Promise<AuthorizationDTO> {
        const response = await this.registerRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateProductRaw(requestParameters: UpdateProductRequest): Promise<runtime.ApiResponse<UserProductDTO>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling updateProduct.');
        }

        if (requestParameters.updateUserProductDTO === null || requestParameters.updateUserProductDTO === undefined) {
            throw new runtime.RequiredError('updateUserProductDTO','Required parameter requestParameters.updateUserProductDTO was null or undefined when calling updateProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // jwt authentication
        }

        const response = await this.request({
            path: `/products/product/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserProductDTOToJSON(requestParameters.updateUserProductDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProductDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateProduct(requestParameters: UpdateProductRequest): Promise<UserProductDTO> {
        const response = await this.updateProductRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async verifyLoginRaw(): Promise<runtime.ApiResponse<AuthorizationDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-access-token"] = this.configuration.apiKey("x-access-token"); // jwt authentication
        }

        const response = await this.request({
            path: `/auth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizationDTOFromJSON(jsonValue));
    }

    /**
     */
    async verifyLogin(): Promise<AuthorizationDTO> {
        const response = await this.verifyLoginRaw();
        return await response.value();
    }

}
