/* tslint:disable */
/* eslint-disable */
/**
 * bac-pwa
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BrandDTO,
    BrandDTOFromJSON,
    BrandDTOFromJSONTyped,
    BrandDTOToJSON,
    CategoryDTO,
    CategoryDTOFromJSON,
    CategoryDTOFromJSONTyped,
    CategoryDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewUserProductDTO
 */
export interface NewUserProductDTO {
    /**
     * 
     * @type {string}
     * @memberof NewUserProductDTO
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof NewUserProductDTO
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof NewUserProductDTO
     */
    imageName: string;
    /**
     * 
     * @type {number}
     * @memberof NewUserProductDTO
     */
    categoryId: number;
    /**
     * 
     * @type {CategoryDTO}
     * @memberof NewUserProductDTO
     */
    category: CategoryDTO;
    /**
     * 
     * @type {number}
     * @memberof NewUserProductDTO
     */
    brandId: number;
    /**
     * 
     * @type {BrandDTO}
     * @memberof NewUserProductDTO
     */
    brand: BrandDTO;
}

export function NewUserProductDTOFromJSON(json: any): NewUserProductDTO {
    return NewUserProductDTOFromJSONTyped(json, false);
}

export function NewUserProductDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewUserProductDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'price': json['price'],
        'imageName': json['imageName'],
        'categoryId': json['categoryId'],
        'category': CategoryDTOFromJSON(json['category']),
        'brandId': json['brandId'],
        'brand': BrandDTOFromJSON(json['brand']),
    };
}

export function NewUserProductDTOToJSON(value?: NewUserProductDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'price': value.price,
        'imageName': value.imageName,
        'categoryId': value.categoryId,
        'category': CategoryDTOToJSON(value.category),
        'brandId': value.brandId,
        'brand': BrandDTOToJSON(value.brand),
    };
}


