/* tslint:disable */
/* eslint-disable */
/**
 * bac-pwa
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryDTO,
    CategoryDTOFromJSON,
    CategoryDTOFromJSONTyped,
    CategoryDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaginatedResponseCategoryDTO
 */
export interface PaginatedResponseCategoryDTO {
    /**
     * 
     * @type {number}
     * @memberof PaginatedResponseCategoryDTO
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedResponseCategoryDTO
     */
    limit: number;
    /**
     * 
     * @type {Date}
     * @memberof PaginatedResponseCategoryDTO
     */
    since: Date;
    /**
     * 
     * @type {Array<CategoryDTO>}
     * @memberof PaginatedResponseCategoryDTO
     */
    data: Array<CategoryDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedResponseCategoryDTO
     */
    stream?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginatedResponseCategoryDTO
     */
    totalCount: number;
}

export function PaginatedResponseCategoryDTOFromJSON(json: any): PaginatedResponseCategoryDTO {
    return PaginatedResponseCategoryDTOFromJSONTyped(json, false);
}

export function PaginatedResponseCategoryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedResponseCategoryDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offset': json['offset'],
        'limit': json['limit'],
        'since': (new Date(json['since'])),
        'data': ((json['data'] as Array<any>).map(CategoryDTOFromJSON)),
        'stream': !exists(json, 'stream') ? undefined : json['stream'],
        'totalCount': json['totalCount'],
    };
}

export function PaginatedResponseCategoryDTOToJSON(value?: PaginatedResponseCategoryDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offset': value.offset,
        'limit': value.limit,
        'since': (value.since.toISOString()),
        'data': ((value.data as Array<any>).map(CategoryDTOToJSON)),
        'stream': value.stream,
        'totalCount': value.totalCount,
    };
}


