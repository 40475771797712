import {RouteConfig} from 'vue-router';
import {Routes} from './Routes';
import Error from '../views/error/Error.vue';
import NotFound from '../views/error/NotFound.vue';

const errorRoutes: RouteConfig[] = [
    {
        path: '/error',
        component: Error,
        children: [
            {
                path: '/not-found',
                name: Routes.NOT_FOUND,
                component: NotFound
            }
        ]
    }
];

export default errorRoutes;
