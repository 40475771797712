/* tslint:disable */
/* eslint-disable */
/**
 * bac-pwa
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserProductDTO,
    UserProductDTOFromJSON,
    UserProductDTOFromJSONTyped,
    UserProductDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaginatedResponseUserProductDTO
 */
export interface PaginatedResponseUserProductDTO {
    /**
     * 
     * @type {number}
     * @memberof PaginatedResponseUserProductDTO
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedResponseUserProductDTO
     */
    limit: number;
    /**
     * 
     * @type {Date}
     * @memberof PaginatedResponseUserProductDTO
     */
    since: Date;
    /**
     * 
     * @type {Array<UserProductDTO>}
     * @memberof PaginatedResponseUserProductDTO
     */
    data: Array<UserProductDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedResponseUserProductDTO
     */
    stream?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginatedResponseUserProductDTO
     */
    totalCount: number;
}

export function PaginatedResponseUserProductDTOFromJSON(json: any): PaginatedResponseUserProductDTO {
    return PaginatedResponseUserProductDTOFromJSONTyped(json, false);
}

export function PaginatedResponseUserProductDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedResponseUserProductDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offset': json['offset'],
        'limit': json['limit'],
        'since': (new Date(json['since'])),
        'data': ((json['data'] as Array<any>).map(UserProductDTOFromJSON)),
        'stream': !exists(json, 'stream') ? undefined : json['stream'],
        'totalCount': json['totalCount'],
    };
}

export function PaginatedResponseUserProductDTOToJSON(value?: PaginatedResponseUserProductDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offset': value.offset,
        'limit': value.limit,
        'since': (value.since.toISOString()),
        'data': ((value.data as Array<any>).map(UserProductDTOToJSON)),
        'stream': value.stream,
        'totalCount': value.totalCount,
    };
}


