import {Plugin} from 'vuex';
import {RootState} from '@/store';
import {SocketService} from '@/services/SocketService';
import {BrandDTO, CategoryDTO, UserProductDTO} from '@/generated';
import {ProductModuleMutations} from '@/store/modules/ProductModule';

export default <Plugin<RootState>> function websocketPlugin(store) {
    SocketService.on('products', (products: UserProductDTO[]) => {
        const newOffset = (store.state.product.productsSyncInfo.offset || 0) + products.length;
        store.commit(ProductModuleMutations.setProducts, products);
        store.commit(ProductModuleMutations.productsUpdated, newOffset);
    });

    SocketService.on('products-synced', (since: Date) => {
        store.commit(ProductModuleMutations.productsSynced, since);
    });

    SocketService.on('brands', (brands: BrandDTO[]) => {
        const newOffset = (store.state.product.brandsSyncInfo.offset || 0) + brands.length;
        store.commit(ProductModuleMutations.setBrands, brands);
        store.commit(ProductModuleMutations.brandsUpdated, newOffset);
    });

    SocketService.on('brands-synced', (since: Date) => {
        store.commit(ProductModuleMutations.brandsSynced, since);
    });

    SocketService.on('categories', (categories: CategoryDTO[]) => {
        const newOffset = (store.state.product.categoriesSyncInfo.offset || 0) + categories.length;
        store.commit(ProductModuleMutations.setCategories, categories);
        store.commit(ProductModuleMutations.categoriesUpdated, newOffset);
    });

    SocketService.on('categories-synced', (since: Date) => {
        store.commit(ProductModuleMutations.categoriesSynced, since);
    });
};
