



































import {Component, Vue} from 'vue-property-decorator';
import {Routes} from '@/router/Routes';
import {CredentialsDTO} from '@api/api';
import UserModule from '@/store/modules/UserModule';
import {getModule} from 'vuex-module-decorators';

@Component
export default class Register extends Vue {
    readonly userModule = getModule(UserModule, this.$store);
    readonly routes = Routes;

    newUserCredentials: CredentialsDTO = {
        username: '',
        password: ''
    };
    passwordConfirm: string = '';
    formError: string = '';

    async performRegistration() {
        if (this.passwordConfirm !== this.newUserCredentials.password) {
            this.formError = 'Passwords do not match!';
            return;
        }
        try {
            await this.userModule.register(this.newUserCredentials);
        } catch (e) {
            if (e instanceof Response && e.status === 409) {
                this.formError = await e.text();
                return;
            }
            this.formError = 'Unknown server error';
            console.error(e);
            return;
        }
        this.$router.push({name: Routes.PRODUCTS, params: { noValidate: 'true' }});
    }

    checkPasswordValidity(): void {
        const passwordConfirmEl = this.$refs.passwordConfirm;
        if (!(passwordConfirmEl instanceof HTMLInputElement)) {
            return;
        }
        if (this.passwordConfirm !== this.newUserCredentials.password) {
            passwordConfirmEl.setCustomValidity('Passwords do not match');
        } else {
            passwordConfirmEl.setCustomValidity('');
        }
    }
}
