/* tslint:disable */
/* eslint-disable */
/**
 * bac-pwa
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserDTO,
    UserDTOFromJSON,
    UserDTOFromJSONTyped,
    UserDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface AuthorizationDTO
 */
export interface AuthorizationDTO {
    /**
     * 
     * @type {string}
     * @memberof AuthorizationDTO
     */
    token: string;
    /**
     * 
     * @type {UserDTO}
     * @memberof AuthorizationDTO
     */
    user?: UserDTO;
}

export function AuthorizationDTOFromJSON(json: any): AuthorizationDTO {
    return AuthorizationDTOFromJSONTyped(json, false);
}

export function AuthorizationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorizationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'user': !exists(json, 'user') ? undefined : UserDTOFromJSON(json['user']),
    };
}

export function AuthorizationDTOToJSON(value?: AuthorizationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'user': UserDTOToJSON(value.user),
    };
}


