



























import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {Routes} from '@/router/Routes';
import {CredentialsDTO} from '@api/api';
import {getModule} from 'vuex-module-decorators';
import UserModule from '@/store/modules/UserModule';

@Component
export default class Login extends Vue {
    readonly userModule = getModule(UserModule, this.$store);
    readonly routes = Routes;

    userCredentials: CredentialsDTO = {
        username: '',
        password: ''
    };
    formError: string = '';

    async performLogin() {
        try {
            await this.userModule.login(this.userCredentials);
        } catch (e) {
            if (e instanceof Response && e.status === 422) {
                this.formError = await e.text();
                return;
            }
            this.formError = 'Unknown server error';
            console.error(e);
            return;
        }
        this.$router.push({name: Routes.PRODUCTS, params: { noValidate: 'true' }});
    }
}
